import styled from 'styled-components'
import {device} from '../../../../theme'

export const TestimonialWrapper = styled.section `
    padding-top: 100px;
    padding-bottom: 100px;
    // @media (prefers-color-scheme: light) {
    //     background-color: #F8F8F8;
    // }
    @media (prefers-color-scheme: dark) {
        background-color: #121212;
    }
    background-color: #f6f2ed;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
`;