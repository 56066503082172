import styled from 'styled-components'
import {device} from '../../../theme'

export const AboutWrapper = styled.section `
    padding 100px 30px;
    @media ${device.medium}{
        padding-top: 80px;
    }
    @media ${device.small}{
        padding-top: 60px;
    }
`;

export const LeftBox = styled.div `
    h3 mark{
        // @media (prefers-color-scheme: light) {
        //     color: #121212;
        // }
        color: #121212;
        @media (prefers-color-scheme: dark) {
            color: #ffffff;
        }
    }
    @media ${device.medium}{
        margin-bottom: 20px;
    }
`;

export const RightBox = styled.div ``;